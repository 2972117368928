import * as React from 'react';
import {ReactNode} from 'react';
import styled from 'styled-components';
import {AppBarHeight, CenterSectionCSS, Media, PageSectionOptions, Palette} from "../constants";
import {SectionImage} from "./SectionImage";


export type PageSectionProps = {
    img?: string;
    children: ReactNode;
    className?: string;
} & PageSectionOptions;

export function PageSection({id, variant, title, img, children, className}: PageSectionProps) {
    let content = children;

    if (img) {
        content = <PageSectionContent variant={variant}>
            <PageSectionImage src={img} variant={variant}/>
            <Text>{children}</Text>
        </PageSectionContent>
    }
    return <Container id={id} className={className}>
        <PageSectionTitle>{title}</PageSectionTitle>
        {content}
    </Container>;
}


const Container = styled.section`
  ${CenterSectionCSS};
  padding-top: ${AppBarHeight.desktop}rem;           
  @media (${Media.tablet}) {
     padding-top: 0;
  }
  @media (${Media.desktop}) {    
     padding-top: ${AppBarHeight.desktop + 2}rem;        
  } 
  @media (${Media.desktop}) {    
      min-height: 66vh;
  } 
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  
  &:after {
    content: "";
    width: 80%;
    border-bottom: 1px solid #${Palette.Platinum.hex};
    margin: 0 auto;
    padding-top: ${AppBarHeight.desktop}rem;
    
    @media (${Media.tablet}) {
      border-bottom: none;
    }
  }  
`;

const PageSectionTitle = styled.h2`
  text-align: center;
  font-size: 3rem;
  
  @media (${Media.tablet}) {
    text-align: left;
    margin-bottom: 4rem;
  }
`;

const PageSectionImage = styled(SectionImage)`
  
`;

export const PageSectionContent = styled.div<PageSectionOptions>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1rem;
  
  @media (${Media.tablet}) {
    flex-direction: row;        
  }
  
  @media (${Media.desktop}) {
    align-items: center;
    justify-content: center;
    padding: 0 6rem;     
    ${({variant}) => {
      switch (variant) {
          case "left":
              return `flex-direction: row`;
          case "right":
              return `flex-direction: row-reverse`;
      }
    }};    
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1 1 auto;
  width: 100%;
  
  @media (${Media.tablet}) {
    width: auto;
  }
  
  & p {
    padding: 0;
    margin: 0 0 1rem 0;        
  }
  
  & ul {
    padding: 0;
    margin: 0 0 1rem 0;
  }
  
  & li {
    padding: 0;
    margin: 0 0 0 2rem;
  }
`;
